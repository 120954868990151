<template>
  
  <div class="nav-item-row">
    <div class="nav-item-col" v-for="(item, rowIndex) in navigationItems" :key="rowIndex">
      <div :style="getItemStyle(item)">
        <div :style="getItemPadding(item)">
          <a style="display: inline-block;" :style="getTextStyle(item)" :href="ensureHttps(item.url)">
            {{ item?.name }}
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "DynamicNavigationTable",
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
    navigationItems() {
      let navigationItems =
        this.element.propertiesArray[
          this.element.properties.selectedVariationIndex
        ].navigations;
      return navigationItems;
    },
  },
  watch: {
  },
  methods: {
    getTextStyle(item) {
      let selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      let textAlign =
        this.element.propertiesArray[selectedVariationIndex]?.container_style
          ?.textAlign || "center";

      return {
        color: item?.color,
        fontFamily:
          this.element.propertiesArray[
            this.element.properties.selectedVariationIndex
          ].font_family.value,
        ...(item?.isBold && {
          fontWeight: "bold",
        }),
        ...(item?.isItalic && {
          fontStyle: "italic",
        }),
        fontSize: "16px",
        lineHeight: "1.5",
        textAlign: textAlign,
        wordBreak: "break-word",
      };
    },
    getItemPadding() {
      let selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      let columnSpace =
        this.element.propertiesArray[selectedVariationIndex]?.columnSpace || 0;
      return {
        padding: `${columnSpace}px`,
      };
    },

    getItemStyle() {
      let selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      let borderStyles =
        this.element.propertiesArray[selectedVariationIndex]?.borderStyles ||
        {};
      let bgColor =
        {
          backgroundColor: this.element.propertiesArray[selectedVariationIndex]?.style.backgroundColor
        } ||
        {};
      let hasBorder =
        borderStyles.borderTopWidth != "0px" ||
        borderStyles.borderRightWidth != "0px" ||
        borderStyles.borderBottomWidth != "0px" ||
        borderStyles.borderLeftWidth != "0px";


      return hasBorder ? { ...borderStyles, ...bgColor } : { ...bgColor };
    },
    ensureHttps(url) {
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        return `https://${url}`;
      }
      return url;
    },
  },
};
</script>

<style scoped></style>
